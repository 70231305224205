'use client';

import { useLocaleMessage } from '@/react/hooks/useLocaleMessage';
import classNames from 'classnames';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { Breakpoint, Breakpoints } from '../Breakpoints';
import { Button } from '../Button';
import { Icon, IconTypes } from '../Icon';

import S from './styles.module.scss';
import { general_errors_unexpectedError } from "@/lang/__generated__/ahnu/general_errors_unexpectedError";
import { general_errors_closeStacktrace } from "@/lang/__generated__/ahnu/general_errors_closeStacktrace";
import { general_errors_seeStacktrace } from "@/lang/__generated__/ahnu/general_errors_seeStacktrace";

export interface IErrorAlertProps {
  /**
   * The error to be displayed.
   * If `error` is a string, it will be displayed as is.
   * If `error` is of type `Error`, it will display the error message and stack trace.
   * If `error` is of any other type, it will be converted to a string and displayed.
   */
  error: unknown;

  /**
   * The `onClose` event occurs when the alert is closed and removed from the screen.
   * @param error - The error that was displayed in the alert.
   */
  onClose?: (error: unknown) => void;

  /**
   * If `obscureMessage` is specified as true the Alert will display a vague
   * error message, something like: `An unexpected error occurred. Please try again later.`.
   */
  obscureMessage?: boolean;

  /**
   * Determines if the stacktrace is allowed to be displayed if the error is an instance of `Error`.
   * Default is `true`.
   */
  allowStacktrace?: boolean;

  /**
   * Determines if the alert icon should be displayed or not.
   * Default is `true`.
   */
  showAlertIcon?: boolean;

  /**
   * The class name to be applied to the container of the error alert.
   */
  containerClassName?: string;

  /**
   * The class name to be applied to the message of the error alert.
   */
  messageClassName?: string;
}

/**
 * Error Alert component to display any User-Facing errors.
 */
export const ErrorAlert: FunctionComponent<IErrorAlertProps> = ({
  error,
  onClose,
  obscureMessage = false,
  allowStacktrace = true,
  showAlertIcon = true,
  containerClassName,
  messageClassName
}) => {
  const [msg] = useLocaleMessage();
  const [isOpenStack, setIsOpenStack] = useState(false);

  const handleOpenCloseStacktrace = useCallback(() => {
    setIsOpenStack((open) => !open);
  }, []);

  /**
   * Using `useMemo` here we are making sure that `err` const will be recreated
   * just if `error` prop get changed.
   */
  const err = useMemo(() => {
    if (error instanceof Error || typeof error === 'string') {
      return error;
    }

    if (typeof error === 'object') {
      return JSON.stringify(error);
    }

    return String(error);
  }, [error]);

  const [message, stack] =
    typeof err === 'string'
      ? [err, undefined]
      : [
          obscureMessage ? msg(general_errors_unexpectedError) : err.message,
          err.stack
        ];

  const stacktraceBtnText =
    stack && allowStacktrace
      ? isOpenStack
        ? msg(general_errors_closeStacktrace)
        : msg(general_errors_seeStacktrace)
      : null;

  const msgClassName = classNames(S.message, messageClassName);

  return (
    <div className={classNames(S.container, containerClassName)}>
      <Breakpoints>
        <Breakpoint default>
          <div className={S.error}>
            <div className={S.errorMessage}>
              {showAlertIcon ? (
                <Icon icon={IconTypes.Alert} className={S.alertIcon} />
              ) : null}

              <span className={msgClassName}>{message}</span>

              {stack && !obscureMessage && allowStacktrace ? (
                <Button variant="link" onClick={handleOpenCloseStacktrace}>
                  {stacktraceBtnText}
                </Button>
              ) : null}
            </div>

            <Icon
              onClick={onClose}
              title="bannerCloseBtn"
              className={S.close}
              icon={IconTypes.Close}
            />
          </div>
        </Breakpoint>

        <Breakpoint media="phone">
          <div className={S.error}>
            <div className={S.errorMessage}>
              {showAlertIcon ? (
                <Icon icon={IconTypes.Alert} className={S.alertIcon} />
              ) : null}

              <span className={msgClassName}>{message}</span>
            </div>

            <Icon
              onClick={() => onClose?.(error)}
              title="bannerCloseBtn"
              className={S.close}
              icon={IconTypes.Close}
            />
          </div>

          <div className={S.stackTraceBtn}>
            {stack && !obscureMessage && allowStacktrace ? (
              <Button variant="link" onClick={handleOpenCloseStacktrace}>
                {stacktraceBtnText}
              </Button>
            ) : null}
          </div>
        </Breakpoint>
      </Breakpoints>

      {stack && allowStacktrace ? (
        <div
          className={classNames(
            S.stackTraceWrapper,
            isOpenStack && !obscureMessage ? S.openStack : S.closeStack
          )}
        >
          <pre className={S.stackTrace}>{stack}</pre>
        </div>
      ) : null}
    </div>
  );
};
