import { msg } from '@/services/isomorphic/I18NService';
import type { FC } from 'react';
import { Breakpoint, Breakpoints } from '../Breakpoints';
import { general_logoAlt } from "@/lang/__generated__/ahnu/general_logoAlt";

/** Ahnu logo component. */
export const AhnuLogo: FC = () => {
  const alt = msg(general_logoAlt);

  return (
    <Breakpoints>
      <Breakpoint media="desktop">
        <img src="/images/logo.svg" alt={alt} width={150} height={24} />
      </Breakpoint>
      <Breakpoint default>
        <img src="/images/logo.svg" alt={alt} width={135} height={22} />
      </Breakpoint>
    </Breakpoints>
  );
};
